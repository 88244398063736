import { PropsWithChildren } from "react";
import Typography from "../Typography";
import styles from "./Accordion.module.scss";

type Props = {
  title: string;
} & PropsWithChildren;

export default function Accordion({ title, children }: Props) {
  const { details, summary, summary_heading, icon } = styles;

  const handlePropagation = (e: React.MouseEvent<HTMLElement>) =>
    e.stopPropagation();

  return (
    <details className={details}>
      <summary
        role="presentation"
        onClick={handlePropagation}
        className={summary}
      >
        <Typography addClass={summary_heading} as="h4">
          {title}
        </Typography>
        <div className={icon} />
      </summary>
      {children}
    </details>
  );
}
