import { useTranslation } from "next-i18next";
import Image from "next/image";
import Typography from "@components/common/Typography";
import { ChooseUsTextOut } from "@common/type/type";
import styles from "./WhyUs.module.scss";

type Props = {
  dataList: Array<ChooseUsTextOut>;
};

export default function WhyUs({ dataList }: Props) {
  const { t } = useTranslation("home");

  return (
    <section className={styles.section_wrapper}>
      <div className={styles.section_container}>
        <Typography as="h2" addClass={styles.heading}>
          {t("why-us-main-title")}
        </Typography>
        <div className={styles.grid_container}>
          {dataList.map(({ title, content, logo_url }) => (
            <div key={`${title}-grid`} className={styles.grid_item}>
              <div className={styles.item_heading}>
                {logo_url && (
                  <Image width={18} height={18} src={logo_url} alt={title} />
                )}
                <Typography as="h3" addClass={styles.title}>
                  {title}
                </Typography>
              </div>
              <Typography as="p" addClass={styles.description}>
                {content}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
