import { useId } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Typography from "@components/common/Typography";
import { Button } from "@components/common/Button";
import Link from "next/link";
import { AdsOut } from "@common/type/type";
import { AppLanguage } from "@common/enum/enum";
import Advertisement from "../Advertisement";
import styles from "./HowToInvolve.module.scss";

type Props = {
  data: AdsOut;
};

export default function HowToInvolve({ data }: Props) {
  const { t } = useTranslation("home");
  const id = useId();
  const { locale, defaultLocale } = useRouter();

  const lang = (locale || defaultLocale) as AppLanguage;
  const titleMapping = {
    [AppLanguage.EN]: data.title_en,
    [AppLanguage.RU]: data.title_ru,
    [AppLanguage.UA]: data.title_ua,
  };
  const descriptionKeys = new Array(3).fill(null).map((_, i) => `${id}-${i}`);
  const descriptionMapping = {
    [AppLanguage.EN]: [
      data.content_field_1_en,
      data.content_field_2_en,
      data.content_field_3_en,
    ],
    [AppLanguage.RU]: [
      data.content_field_1_ru,
      data.content_field_2_ru,
      data.content_field_3_ru,
    ],
    [AppLanguage.UA]: [
      data.content_field_1_ua,
      data.content_field_2_ua,
      data.content_field_3_ua,
    ],
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Typography as="h2" addClass={styles.title}>
          {titleMapping[lang]}
        </Typography>
        {descriptionMapping[lang].map((value, index) => (
          <div key={descriptionKeys[index]} className={styles.list_item}>
            <div className={styles.list_counter}>{index + 1}</div>
            <Typography as="p" addClass={styles.list_description}>
              {value}
            </Typography>
          </div>
        ))}
        <div className={styles.button_container}>
          <Link href={data.button_url || "#"} target="_blank">
            <Button className={styles.involve_button}>
              {t("how-to-involve-button")}
            </Button>
          </Link>
        </div>
      </div>
      <Advertisement ads={data} />
    </div>
  );
}
