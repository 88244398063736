import Typography from "@components/common/Typography";
import { useTranslation } from "next-i18next";
import styles from "./StepItem.module.scss";

type Props = {
  illustration: JSX.Element;
  title: string;
  description: string;
};

export default function StepItem({ illustration, title, description }: Props) {
  const { t } = useTranslation("home");

  return (
    <div key={title} className={styles.step_wrapper}>
      <div className={styles.step_container}>
        <div className={styles.step_heading}>{illustration}</div>
        <div className={styles.step_content}>
          <Typography as="h3" addClass={styles.step_title}>
            {t(title)}
          </Typography>
          <Typography as="p" addClass={styles.step_description}>
            {t(description)}
          </Typography>
        </div>
      </div>
    </div>
  );
}
