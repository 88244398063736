import { useContext, useRef } from "react";
import { useTranslation } from "next-i18next";
import Typography from "@components/common/Typography";
import { GlobalContext } from "@context/GlobalContext";
import CurrencyCalculator from "@components/common/CurrencyCalculator";
import {
  AdsOut,
  ChooseUsTextOut,
  FaqOut,
  MainPageStatistics,
} from "@common/type/type";
import { HOME_TABLE_TITLE_ID } from "@common/constant/constant";
import dynamic from "next/dynamic";
import WhyUs from "./WhyUs";
import HowToExchange from "./HowToExchange";
import HowToInvolve from "./HowToInvole";
import FAQ from "./FAQ";
import MainStatistics from "./MainStatistics";
import styles from "./HomePage.module.scss";

const DynamicExchangeTable = dynamic(
  () => import("@components/ui/ExchangeTable")
);
const DynamicExchangeBottom = dynamic(
  () => import("@components/ui/ExchangeBottom")
);

type Props = {
  homeData: {
    chooseUsData: Array<ChooseUsTextOut>;
    adsDataList: Array<AdsOut>;
    statisticsData: MainPageStatistics;
    faqDataList: Array<FaqOut>;
  };
};

export default function HomePage({ homeData }: Props) {
  const { chooseUsData, adsDataList, statisticsData, faqDataList } = homeData;

  const { state } = useContext(GlobalContext);
  const { currency_pairs } = state;
  const { t } = useTranslation(["home"]);
  const elementRef = useRef<HTMLDivElement>(null);

  const isFullPair = currency_pairs.every(({ id }) => id);

  return (
    <div className={styles.container}>
      <section className={styles.exchange_container}>
        <div className={styles.heading}>
          <Typography as="h1">{t("h1")}</Typography>
          <Typography addClass={styles.description} as="p">
            {t("description")}
          </Typography>
        </div>
        <CurrencyCalculator forceDescription />
        <div id={HOME_TABLE_TITLE_ID} ref={elementRef}>
          {isFullPair && <DynamicExchangeTable />}
        </div>

        {isFullPair && (
          <DynamicExchangeBottom
            pair={[currency_pairs[0], currency_pairs[1]]}
          />
        )}
      </section>
      {!isFullPair && (
        <>
          {!!chooseUsData.length && <WhyUs dataList={chooseUsData} />}
          <HowToExchange />
          {!!adsDataList.length && <HowToInvolve data={adsDataList[0]} />}
          {statisticsData && <MainStatistics data={statisticsData} />}
          {!!faqDataList.length && <FAQ dataList={faqDataList} />}
        </>
      )}
    </div>
  );
}
