import Link from "next/link";
import { AdsOut } from "@common/type/type";
import { useRouter } from "next/router";
import { AppLanguage } from "@common/enum/enum";
import styles from "./Advertisement.module.scss";

type Props = {
  ads: AdsOut;
};

export default function Advertisement({ ads }: Props) {
  const { locale, defaultLocale } = useRouter();
  const lang = (locale || defaultLocale) as AppLanguage;
  const { ads_url, ru_image, en_image, ua_image } = ads;
  const imageMapping = {
    [AppLanguage.EN]: en_image,
    [AppLanguage.RU]: ru_image,
    [AppLanguage.UA]: ua_image,
  };

  return (
    <div className={styles.wrapper}>
      <Link href={ads_url} target="_blank">
        <img className={styles.image} src={imageMapping[lang]} alt="" />
      </Link>
    </div>
  );
}
