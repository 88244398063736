import { useTranslation } from "next-i18next";
import Typography from "@components/common/Typography";
import EtherIcon from "@public/svg/icons/ether.svg";
import CompareArrows from "@public/svg/icons/compare_arrows.svg";
import BitcoinIcon from "@public/svg/icons/btc.svg";
import useMediaQuery from "app/hooks/useMediaQuery";
import dynamic from "next/dynamic";
import StepItem from "./StepItem";
import styles from "./HowToExchange.module.scss";

const DynamicSwiper = dynamic(
  () => import("./HowToExchangeSlider/HowToExchangeSlider")
);

export const gridMapping = [
  {
    illustration: (
      <div className={styles.swap_container}>
        <div className={styles.icon_container}>
          <EtherIcon />
        </div>
        <div className={styles.icon_swap}>
          <CompareArrows />
        </div>
        <div className={styles.icon_container}>
          <BitcoinIcon />
        </div>
      </div>
    ),
    title: "how-to-exchange-1.title",
    description: "how-to-exchange-1.description",
  },
  {
    illustration: (
      <Typography as="span" addClass={styles.address}>
        gdh47hej38djdfu348dfj****
      </Typography>
    ),
    title: "how-to-exchange-2.title",
    description: "how-to-exchange-2.description",
  },
  {
    illustration: (
      <div className={styles.icon_container}>
        <div className={styles.smile}>🤑</div>
      </div>
    ),
    title: "how-to-exchange-3.title",
    description: "how-to-exchange-3.description",
  },
];

export default function HowToExchange() {
  const { t } = useTranslation("home");
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className={styles.wrapper}>
      <Typography as="h2" addClass={styles.heading}>
        {t("how-to-exchange-title")}
      </Typography>
      {isMobile ? (
        <DynamicSwiper />
      ) : (
        <div className={styles.grid_container}>
          {gridMapping.map(({ illustration, title, description }) => (
            <StepItem
              key={title}
              illustration={illustration}
              title={title}
              description={description}
            />
          ))}
        </div>
      )}
    </div>
  );
}
