import { useTranslation } from "react-i18next";
import Typography from "@components/common/Typography";
import { LocationIcon } from "@components/common/Icon";
import OperationIcon from "@public/svg/icons/operation_count.svg";
import CurrencyIcon from "@public/svg/icons/view_day.svg";
import { MainPageStatistics } from "@common/type/MainPageStatistics.type";
import styles from "./MainStatistics.module.scss";

const getMapping = ({
  currencies_total,
  exchange_operations_total,
  exchangers_total,
}: MainPageStatistics) => {
  return [
    {
      Icon: <OperationIcon />,
      title: "exchange",
      value: exchange_operations_total,
    },
    { Icon: <LocationIcon />, title: "place", value: exchangers_total },
    { Icon: <CurrencyIcon />, title: "count", value: currencies_total },
  ];
};

type Props = {
  data: MainPageStatistics;
};

export default function MainStatistics({ data }: Props) {
  const { t } = useTranslation("home");

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {getMapping(data).map(({ Icon, value, title }) => (
          <div key={`${title}-statistics`} className={styles.item_wrapper}>
            <div className={styles.item}>
              <div className={styles.icon_container}>
                <div className={styles.icon}>{Icon}</div>
              </div>
              <div className={styles.heading}>
                <Typography addClass={styles.title} as="h4">
                  {value}
                </Typography>
                <Typography addClass={styles.description} as="p">
                  {t(title)}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
