import { useTranslation } from "next-i18next";
import Typography from "@components/common/Typography";
import Accordion from "@components/common/Accordion";
import { FaqOut } from "@common/type/type";
import styles from "./FAQ.module.scss";

type Props = {
  dataList: Array<FaqOut>;
};

export default function FAQ({ dataList }: Props) {
  const { t } = useTranslation("home");

  return (
    <section className={styles.wrapper}>
      <Typography as="h2" addClass={styles.title}>
        {t("faq-title")}
      </Typography>
      <div className={styles.grid_container}>
        {dataList?.map(({ title, content, id }) => (
          <div key={id}>
            <Accordion title={title}>{content}</Accordion>
          </div>
        ))}
      </div>
    </section>
  );
}
